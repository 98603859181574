<template>
  <v-dialog 
    transition="dialog-top-transition"
    v-model="showDialog" width="50%"> 
            <v-card  class="card_home_container autolettura_dialog_card">
                  <v-card-title class="text-h6">                        
                      <v-row class="TitleDialog d-flex" justify="center" align="left" >
                        <v-col cols="1">
                          <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-contatore2.svg')" />
                        </v-col>
                        <v-col cols="9" class="">
                            <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_autolettura_dialog_title')}}</span>
                        </v-col>
                        <v-col cols="1" class=".float-end">
                          <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  @click="closeDialog()" />
                        </v-col>
                      </v-row>
                  </v-card-title>

                <v-card-text class="text-subtitle-1 pa-5 ">

                      <v-row class="FirstTextDialog d-flex" justify="center" align="left" >
                        <v-col cols="6" class="">
                          <p class="autoletturatile_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_autolettura_dialog_text")'></p>
                        </v-col>
                        <v-col cols="6">
                          <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/autolettura-image.svg')" />
                        </v-col>
                      </v-row>
                      <v-form
                          ref="form"
                          v-model="valid"
                        >
                          <v-row >
                          <v-text-field 
                          v-model="lettura_val" outlined
                          :rules="[v=> checkAutolettura(v)]"
                          type="number"
                          >
                          </v-text-field>
                      </v-row>
                      </v-form>

                      <v-row justify="center" align="center">
                        <v-btn
                            class="dialog_autolettura_save_button white--text"
                            @click="Save()"
                            x-large
                            color="black"                     
                            >
                            {{$t('message.bolletta_interattiva_template5_autolettura_save')}}          
                        </v-btn>
                      </v-row>
                      

                </v-card-text>
              </v-card>
          </v-dialog>
</template>

<script>
import utility from '../Core/utility';

export default {
  mixins: [utility],
  data() {
    return {
      valid:true,
      lettura_val:null
    }
  },
 computed: {

  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
        showDialog:{
          type: Boolean,
          default: false
        }
    },
  methods:{
      closeDialog: function(){
          this.$emit('close');        
      },
      checkAutolettura: function (value) {
          if (value == null || value.toString().length == 0)
              return true;
          return /^\d+$/.test(value) || this.$t("message.bolletta_interattiva_template5_autolettura_dialog_check_autolettura");
      },
      Save: function () {
          let that = this;
          that.valid = that.$refs.form.validate();
          if(that.valid && this.lettura_val.trim().length > 0)
            this.$emit('close');
        },
  }

}
</script>

<style>

</style>